import dayjs from "dayjs";
import { Link, useNavigate } from "react-router-dom";
import { DAILY_EMOVI } from "../../data/games";
import UserContext from "../../UserProvider";
import { useContext, useEffect } from "react";
import { BecomePremiumMember } from "../../components/BecomeAMember";

export function ReplayRoute() {

    const navigate = useNavigate();
    const { userDetails } = useContext(UserContext);
    let gameNumber = 0;
    let games = Object.keys(DAILY_EMOVI).sort().map(date => {
        return {
            game: gameNumber++,
            date,
            text: DAILY_EMOVI[date].emojiText,
        }
    });
    games = games.filter(game => dayjs(game.date).isBefore(dayjs())).reverse();
    // Remove last element as it is game 0
    games.pop();
    const todaysGame = games[0];
    useEffect(() => {
        if(userDetails?.premiumGames?.includes('emovi')) {
            navigate(`/archive/${todaysGame.date}`);
        }
    }, []);

    return (
        <>
            <div className="px-4 md:px-0">
                <div className="mb-4 mt-4">
                    <Link to="/">
                        <span className="text-blue-500 hover:text-blue-400">
                            &#8592; Back
                        </span>
                    </Link>
                </div>
                <h2 className="text-xl font-bold mb-2">Replay games</h2>
                    <>
                        <p className="mb-3">Replaying games is an exclusive feature for Premium Members.</p>
                        <div className="flex flex-col gap-2">
                            <BecomePremiumMember />
                        </div>
                    </>
            </div>
        </>
    )
}
