import { ReactNode, createContext, useEffect, useState } from 'react';
import { UserDetails } from './interfaces/UserDetails';
import { initialState } from './data/userDetailsInitial';
import { getUserDetails, refreshTokens } from './services/database';
import { clearCookie, getCookie } from './services/cookies';
import { checkPhotoValidity, isSameUtcDay } from './services/utilities';
import { calculateStats } from './services/stats';
import { getDayStringNegOne } from './services/utilities'
import { loadAdScript } from './services/ads';

const UserContext = createContext({
    userDetails: initialState,
    setUserDetails: (userDetails: any) => { },
    statsUpdated: false,
    setStatsUpdated: (statsUpdated: boolean) => { },
});

export const UserProvider = ({ children }: { children: ReactNode }) => {
    const [userDetails, setUserDetails] = useState<UserDetails>(initialState);
    const [statsUpdated, setStatsUpdated] = useState(false);

    // Trying to update localStats
    function updateLocalStats(stats: string) {
        const localStats = calculateStats();
        const statsParsed = JSON.parse(stats);
        if (statsParsed?.played >= localStats.played) {
            
            const dayString = getDayStringNegOne();
            let guessesParsed = JSON.parse(statsParsed.guesses);

            if (!guessesParsed[dayString]) {
                guessesParsed[dayString] = {
                    movieGuessed: false,
                    invalidGuessIds: [],
                };
            }

            localStorage.setItem('guesses', JSON.stringify(guessesParsed));
            setStatsUpdated(true);
        }
    }

    useEffect(() => {
        async function loadUserAndRefreshTokens() {
            const isLoggedIn = getCookie('isLoggedIn') === "true";
            const encodedRefreshTime = getCookie('refreshDate');
            const refreshTime = encodedRefreshTime ? decodeURIComponent(encodedRefreshTime) : null;

            // Load user from local storage
            const userFromLocalStorage = localStorage.getItem('emovi-user');

            if (isLoggedIn) {
                if (userFromLocalStorage) {
                    const parsedUser: UserDetails = JSON.parse(userFromLocalStorage);
                    setUserDetails(parsedUser);
                }
                try {
                    if(refreshTime && !isSameUtcDay(refreshTime)) {
                        console.log("Refreshing tokens...")
                        await refreshTokens();
                    } else {
                        console.log("Not refreshing tokens");
                    }
                    const response = await getUserDetails();

                    const newUserDetails: UserDetails = {
                        loggedIn: isLoggedIn,
                        firstName: response.data.firstName,
                        photoURL: response.data.photoURL,
                        isPhotoValid: false, // default to false until we verify the photo
                        marketingOptIn: String(response.data.marketingOptIn),
                        wheretaken: response.data.wheretaken,
                        wheretakenusa: response.data.wheretakenusa,
                        worldle: response.data.worldle,
                        flagle: response.data.flagle,
                        statele: response.data.statele,
                        emovi: response.data.emovi,
                        premiumGames: response.data.premiumGames,
                    };

                    if(response.data.subscription && !response.data.premiumGames?.includes('emovi')) {
                        console.log('RJC: loading ads...');
                        loadAdScript();
                    }

                    setUserDetails(newUserDetails);
                    checkPhotoValidity(newUserDetails.photoURL).then(isValid => {
                        setUserDetails(prevState => {
                            const updatedUserDetails = {
                                ...prevState,
                                isPhotoValid: isValid
                            };
                            localStorage.setItem('emovi-user', JSON.stringify(updatedUserDetails));
                            return updatedUserDetails;
                        });
                    });
                    if (response.data.emovi && response.data.emovi !== "") {
                        updateLocalStats(response.data.emovi);
                    }

                } catch (e) {
                    clearCookie('isLoggedIn');
                    localStorage.setItem('emovi-user', JSON.stringify(initialState));
                    console.log(e);
                }
            } else {
                console.log('RJC: loading ads...');
                loadAdScript();
            }
        }
        loadUserAndRefreshTokens();
    }, []);

    return (
        <UserContext.Provider value={{ userDetails, setUserDetails, statsUpdated, setStatsUpdated }}>
            {children}
        </UserContext.Provider>
    )
}

export default UserContext;