export function loadAdScript() {

    console.log("Loading ad script");
    const href = window.location.href;
    let src = "";
    if (href.includes('staging') || href.includes('localhost')) {
      console.log('Loading STAGING ads script');
      src =
        'https://staging-cdn.snigelweb.com/adengine/emovi.teuteuf.fr/loader.js';
    } else {
      console.log('Loading LIVE ads script');
      src = 'https://cdn.snigelweb.com/adengine/emovi.teuteuf.fr/loader.js';
    }
  
    let node = document.createElement('script');
    node.src = src;
    node.type = 'text/javascript';
    node.async = true;
    node.setAttribute("data-cfasync", "false");
    document.getElementsByTagName('head')[0].appendChild(node);
  }